import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)" >
<path d="M5860 7548 c-1 -398 -3 -861 -6 -1030 -3 -169 -3 -311 0 -317 3 -5
43 6 94 26 227 90 399 126 501 104 29 -6 59 -11 66 -11 13 0 15 106 15 789 0
619 -3 791 -12 798 -7 6 -49 29 -93 53 -96 51 -291 158 -323 177 -80 49 -214
113 -224 107 -6 -4 -8 -3 -4 4 3 6 2 13 -4 17 -7 4 -10 -230 -10 -717z"/>
<path d="M5195 8129 c-195 -23 -333 -54 -492 -110 -324 -114 -625 -314 -855
-567 -66 -72 -208 -255 -208 -268 0 -3 -10 -20 -22 -37 -37 -51 -138 -248
-153 -297 -4 -14 -11 -29 -15 -35 -10 -13 -76 -225 -89 -285 -73 -337 -72
-637 5 -957 20 -85 70 -239 94 -290 11 -24 20 -45 20 -49 0 -14 107 -215 153
-288 199 -314 504 -588 843 -759 67 -34 242 -107 253 -107 3 0 -50 28 -119 61
-329 158 -590 400 -778 721 -82 140 -158 333 -191 483 -29 135 -34 173 -38
346 -6 215 4 325 46 489 30 118 89 291 101 300 4 3 11 16 15 30 9 32 85 164
148 258 91 136 222 276 372 397 27 22 59 48 69 58 11 9 23 17 27 17 4 0 34 18
67 39 73 49 241 133 302 151 25 8 50 17 55 20 31 22 217 66 380 90 123 19 457
6 575 -21 13 -4 15 34 18 285 1 159 -1 293 -6 298 -29 29 -412 47 -577 27z"/>
<path d="M6602 7431 l3 -329 89 -90 c48 -49 93 -97 99 -108 6 -10 16 -23 22
-29 27 -23 86 -107 142 -200 109 -182 181 -367 223 -574 29 -145 47 -498 26
-518 -3 -4 -6 -23 -6 -44 0 -154 -115 -502 -229 -694 -29 -48 -74 -118 -81
-125 -3 -3 -32 -40 -64 -82 -59 -79 -191 -211 -281 -283 -27 -22 -52 -43 -55
-46 -14 -18 -166 -108 -280 -166 -36 -18 -54 -30 -40 -27 35 9 253 119 317
160 30 19 56 34 59 34 3 0 58 40 122 89 141 108 318 284 408 406 16 22 37 49
47 59 9 11 17 26 17 33 0 7 4 13 8 13 5 0 16 15 25 33 10 17 26 46 37 62 31
49 130 252 130 269 0 8 4 17 9 20 9 6 66 176 76 224 2 12 7 27 10 32 6 10 11
32 39 195 56 320 12 745 -109 1050 -113 285 -250 497 -455 704 -69 70 -141
140 -160 157 -46 39 -136 104 -144 104 -4 0 -5 -148 -4 -329z"/>
<path d="M4905 7400 c-13 -6 -15 -9 -5 -9 8 0 22 4 30 9 18 12 2 12 -25 0z"/>
<path d="M4845 7380 c-211 -86 -325 -147 -460 -248 -393 -293 -639 -745 -674
-1242 -35 -472 142 -949 477 -1288 456 -462 1127 -622 1758 -421 50 16 154 60
230 99 482 241 813 688 905 1220 22 131 27 373 9 505 -45 323 -183 625 -405
886 l-80 94 -3 -56 c-3 -52 1 -62 47 -137 257 -413 289 -933 84 -1371 -33 -71
-189 -311 -202 -311 -3 0 -5 145 -3 322 2 178 0 360 -3 406 l-7 82 -34 0 c-22
0 -34 -5 -34 -13 0 -8 -11 -24 -24 -36 -18 -17 -25 -19 -29 -9 -4 10 -9 9 -20
-4 -8 -10 -24 -18 -36 -18 -11 0 -21 -4 -21 -10 0 -5 -18 -10 -40 -10 -22 0
-40 -4 -40 -10 0 -13 -37 -50 -49 -50 -5 0 -12 -6 -14 -12 -4 -10 -8 -10 -15
1 -8 11 -17 7 -43 -23 -24 -28 -34 -34 -41 -24 -7 10 -11 9 -22 -4 -7 -10 -26
-18 -43 -18 -31 0 -83 -38 -83 -62 0 -10 -3 -9 -9 1 -9 14 -11 9 -24 -79 -3
-14 -6 13 -7 60 l-4 85 -6 -70 -6 -70 -5 90 c-2 50 -6 -153 -9 -449 l-5 -539
-50 -17 c-27 -9 -54 -15 -60 -13 -6 2 -9 113 -7 316 3 288 -9 776 -16 645 -2
-32 -7 -58 -12 -58 -6 0 -10 10 -10 22 0 12 -3 18 -7 15 -3 -4 -6 -24 -6 -44
0 -21 -2 -28 -4 -15 -6 28 -38 29 -53 2 -8 -14 -17 -19 -30 -15 -12 4 -25 -3
-39 -21 -16 -20 -32 -28 -68 -32 -37 -4 -55 -12 -81 -38 -19 -20 -43 -34 -57
-34 -31 0 -103 -37 -118 -61 -7 -11 -23 -19 -39 -19 -30 0 -77 -40 -80 -68 0
-9 -4 3 -8 28 l-7 45 -3 -45 -3 -45 -6 45 c-13 86 -23 -109 -19 -373 l3 -249
-37 6 c-20 3 -48 10 -62 16 l-26 10 -1 237 c0 131 -4 261 -9 288 -5 33 -8 40
-9 21 0 -16 -4 -26 -8 -23 -3 4 -12 2 -20 -4 -8 -7 -13 -8 -13 -1 0 12 -112
-42 -129 -62 -7 -8 -30 -17 -52 -21 -22 -4 -60 -22 -84 -40 -24 -19 -60 -37
-79 -41 -20 -3 -36 -10 -36 -15 0 -5 -9 -9 -20 -9 -18 0 -25 -11 -21 -33 1 -5
-4 -7 -11 -4 -14 5 -14 10 -2 42 6 17 4 16 -13 -4 -16 -19 -21 -21 -25 -9 -11
27 185 263 378 456 l154 153 0 234 0 234 -27 14 c-125 64 -537 290 -600 329
l-33 20 0 -651 c0 -358 -4 -651 -8 -651 -23 0 -140 181 -197 305 -105 226
-130 345 -130 620 1 182 4 222 23 303 32 134 57 203 118 329 145 296 360 509
674 665 100 51 136 74 75 48z m-180 -1200 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7
14 6 0 13 -4 16 -10z"/>
<path d="M5070 6505 c0 -736 3 -863 22 -817 14 36 456 336 606 411 l42 21 0
425 0 425 -37 23 c-21 13 -99 56 -173 97 -74 40 -175 95 -224 123 -162 89
-215 117 -225 117 -7 0 -11 -271 -11 -825z"/>
<path d="M6110 4100 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z"/>
<path d="M4748 4073 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5245 4012 c-91 -37 -129 -131 -94 -234 l20 -62 -29 -27 c-44 -41
-66 -107 -59 -177 8 -94 47 -154 132 -203 85 -49 197 -39 294 27 20 13 40 24
46 24 6 0 30 -18 54 -41 l43 -41 44 43 c24 24 44 48 44 55 0 6 -18 28 -40 48
-22 20 -40 39 -40 43 0 4 21 29 47 55 l47 48 -44 45 c-24 25 -48 45 -53 45 -5
0 -28 -20 -51 -45 -43 -45 -68 -55 -83 -32 -4 6 -46 46 -93 87 -127 112 -150
138 -150 172 0 48 16 68 55 68 46 0 68 -32 60 -86 -6 -36 -3 -42 38 -82 l44
-44 24 34 c19 26 25 49 28 106 3 71 2 73 -34 117 -20 25 -53 52 -72 60 -46 19
-128 18 -178 -3z m56 -409 c8 -10 44 -44 79 -77 52 -47 62 -61 53 -75 -15 -24
-53 -41 -93 -41 -81 0 -128 56 -121 143 1 17 46 67 60 67 4 0 13 -8 22 -17z"/>
<path d="M6459 3903 c-34 -70 -84 -175 -111 -233 -171 -358 -176 -368 -165
-371 1 0 29 -3 62 -5 90 -8 119 0 136 39 l14 32 129 5 c139 5 150 2 162 -45 4
-15 15 -26 32 -29 36 -7 148 -5 161 3 6 4 -10 50 -45 121 -63 132 -78 163
-169 355 -98 208 -119 248 -132 253 -8 2 -36 -45 -74 -125z m105 -264 c10 -33
27 -76 36 -95 20 -39 29 -35 -78 -36 -35 0 -65 2 -67 4 -2 2 5 23 15 48 10 25
26 66 36 93 9 26 22 47 28 47 6 0 19 -27 30 -61z"/>
<path d="M3977 4003 c-4 -3 -7 -158 -7 -344 0 -384 -3 -369 79 -369 27 0 62 3
80 6 l31 6 2 160 3 160 40 -58 c22 -33 42 -61 45 -64 3 -3 14 -18 24 -35 10
-16 44 -63 74 -103 l56 -73 105 2 c58 1 109 3 113 6 4 2 -2 18 -14 36 -42 60
-131 182 -190 260 -32 42 -58 80 -58 85 0 4 10 19 23 33 12 15 71 84 132 155
60 71 114 132 120 136 5 4 -42 8 -106 8 l-116 0 -49 -56 c-93 -107 -139 -158
-171 -194 l-32 -35 -1 143 0 142 -88 0 c-49 0 -92 -3 -95 -7z"/>
<path d="M3862 3104 l3 -119 58 1 c33 1 70 7 83 14 59 32 78 122 37 174 -13
16 -23 25 -23 20 0 -5 -4 -2 -10 6 -6 10 -32 16 -80 18 l-71 4 3 -118z m141
58 c11 -10 17 -30 17 -55 0 -55 -30 -87 -82 -87 l-38 0 0 78 c0 43 2 81 5 83
9 10 81 -4 98 -19z"/>
<path d="M4205 3178 c-9 -24 -23 -56 -30 -73 -22 -47 -43 -114 -38 -119 10 -9
35 7 43 28 7 19 14 21 65 18 45 -3 58 -8 62 -22 4 -15 43 -37 43 -24 0 11 -41
125 -64 177 -32 72 -56 76 -81 15z m56 -45 c20 -53 17 -68 -16 -69 -40 -2 -46
10 -27 57 18 46 29 49 43 12z"/>
<path d="M4420 3210 c0 -5 0 -13 0 -17 0 -5 12 -8 27 -8 l28 -1 3 -97 c2 -88
4 -97 22 -97 18 0 20 9 22 98 3 89 5 97 23 98 11 1 26 2 33 3 6 1 12 8 12 16
0 12 -16 15 -85 15 -50 0 -85 -4 -85 -10z"/>
<path d="M4726 3188 c-8 -18 -27 -64 -41 -103 -15 -38 -29 -76 -32 -82 -3 -8
3 -13 17 -13 13 0 24 8 27 20 5 17 14 20 63 20 50 0 60 -3 65 -20 4 -14 15
-20 33 -20 15 0 22 4 16 8 -6 4 -22 39 -36 77 -48 128 -57 145 -78 145 -13 0
-25 -11 -34 -32z m50 -40 c24 -71 23 -83 -10 -86 -42 -5 -49 8 -29 61 19 52
28 58 39 25z"/>
<path d="M5089 3192 c-25 -23 -32 -38 -36 -78 -4 -45 -2 -52 30 -89 32 -37 39
-40 85 -40 42 0 56 5 81 29 32 31 40 56 18 56 -8 0 -24 -11 -37 -25 -20 -22
-32 -25 -71 -23 -26 2 -46 5 -44 7 3 2 -2 13 -10 25 -58 81 52 183 119 111 23
-24 56 -34 56 -16 0 5 -13 23 -29 40 -25 27 -36 31 -80 31 -41 0 -56 -5 -82
-28z"/>
<path d="M5390 3190 c-27 -24 -34 -39 -38 -79 -4 -45 -1 -52 29 -85 35 -38 91
-56 126 -41 10 5 21 8 25 7 3 -1 20 14 37 34 45 50 44 109 -3 158 -30 33 -38
36 -88 36 -46 0 -59 -4 -88 -30z m123 -12 c33 -17 46 -39 47 -75 0 -74 -92
-112 -145 -58 -68 67 14 178 98 133z"/>
<path d="M5680 3105 c0 -108 1 -115 20 -115 18 0 20 7 20 69 0 39 4 72 9 75 4
3 32 -28 62 -69 32 -45 60 -75 71 -75 16 0 18 11 18 115 0 108 -1 115 -20 115
-17 0 -19 -8 -22 -77 l-3 -78 -58 78 c-38 50 -65 77 -77 77 -19 0 -20 -7 -20
-115z"/>
<path d="M5980 3105 c0 -110 1 -115 21 -115 19 0 20 5 18 70 -1 39 0 73 3 75
6 6 28 -19 87 -99 18 -25 41 -46 51 -46 16 0 18 12 19 115 2 110 1 115 -19
115 -18 0 -20 -6 -20 -68 0 -38 -3 -72 -7 -75 -3 -4 -31 27 -61 68 -34 48 -61
75 -73 75 -18 0 -19 -9 -19 -115z"/>
<path d="M6280 3105 l0 -115 68 0 c54 0 69 3 69 15 0 11 -12 15 -49 15 l-49 0
3 33 c3 29 6 32 41 35 54 5 51 32 -4 32 -39 0 -40 1 -37 33 3 30 6 32 46 35
28 2 42 8 42 18 0 11 -15 14 -65 14 l-65 0 0 -115z"/>
<path d="M6544 3208 c-23 -11 -64 -76 -64 -102 0 -9 9 -35 21 -57 39 -77 138
-91 192 -27 15 18 27 36 27 40 0 17 -30 7 -54 -17 -26 -26 -89 -35 -101 -14
-4 6 -12 8 -19 6 -7 -3 -12 13 -14 49 -3 46 0 57 22 79 14 14 35 25 46 25 26
0 62 -18 73 -36 9 -15 47 -19 47 -5 0 5 -14 23 -32 40 -27 26 -39 31 -77 30
-25 0 -56 -5 -67 -11z"/>
<path d="M6780 3201 c0 -16 6 -19 33 -18 l32 2 3 -97 c3 -91 4 -98 23 -98 19
0 20 5 17 95 -3 102 -4 100 50 95 16 -1 22 4 22 19 0 20 -6 21 -90 21 -82 0
-90 -2 -90 -19z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
